import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import SitemarkIconImage from './images/saasbotai.png';


  export default function SitemarkIcon() {
    return (
        <img height={50} src={SitemarkIconImage} alt="Saasbotai Logo" />
    );
  }

