import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Card, CardContent, CardActions, Button, Typography, Grid } from '@mui/material';
import { get_api,post_api } from '../utils/api';

const PlansPage = () => {
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetching plan data from your API
    const fetchPlans = async () => {
      try {
        const response = await get_api('/api/plans/');
        console.log(response);
        setPlans(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching plans:', error);
        setLoading(false);
      }
    };

    fetchPlans();
  }, []);

  const handlePurchase = async (plan) => {
    try {

      const orderResponse = await post_api('/api/create-order/', {
        amount: plan.price * 100, // Razorpay uses smallest currency units (cents, paisa, etc.)
        currency: 'USD',
      });
  
      const { order_id, razorpay_key, amount } = orderResponse.data;
  
      const options = {
        key: razorpay_key,
        amount: amount,
        currency: 'USD',
        name: "Subscription Purchase",
        description: `${plan.name} - ${plan.duration} days`,
        order_id: order_id,
        handler: async function (response) {
          await post_api('/api/verify-payment/', {
            razorpay_order_id: response.razorpay_order_id,
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_signature: response.razorpay_signature,
          });
          alert('Payment successful, subscription activated!');
        },
        theme: {
          color: '#3399cc',
        },
      };
  
      const razorpay = new window.Razorpay(options);
      razorpay.open();
    } catch (error) {
      console.error('Error processing payment:', error);
    }
  };
  

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" gutterBottom>
        Choose Your Plan
      </Typography>
      <Grid container spacing={4}>
        {plans.map((plan) => (
          <Grid item xs={12} sm={6} md={4} key={plan.id}>
            <Card>
              <CardContent>
                <Typography variant="h5">{plan.name}</Typography>
                <Typography variant="body2" color="text.secondary">
                  {plan.description}
                </Typography>
                <Typography variant="h6" sx={{ marginTop: 2 }}>
                  ${plan.price} / {plan.duration} days
                </Typography>
                <Typography variant="body1">
                  Credits: {plan.credits}
                </Typography>
                {Object.entries(plan.data).map(([feature, value]) => (
                  <Typography variant="body2" key={feature}>
                    {feature}: {value ? (value === true ? "Yes" : value) : "No"}
                  </Typography>
                ))}
              </CardContent>
              <CardActions>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handlePurchase(plan)}
                >
                  Subscribe Now
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default PlansPage;
