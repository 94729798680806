// src/pages/Dashboard.js

import React, { useState, useEffect } from 'react';
import { Typography, Button, Box, TextField, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemText, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import axios from 'axios';
import {get_api, post_api, delete_api} from '../utils/api';
import DeleteIcon from '@mui/icons-material/Delete';
import Card from '@mui/material/Card';

const Dashboard = () => {
  const [bots, setBots] = useState([]);
  const [open, setOpen] = useState(false);
  const [botName, setBotName] = useState('');
  const [botbotName, setBotbotName] = useState('');
  const [botDescription, setBotDescription] = useState('');
  const [profiledata, setprofiledata] = useState(null);

  const fetchBots = async () => {
    const token = localStorage.getItem('accessToken');
    const response = await get_api('/api/bots/');
    setBots(response.data);
  };
  const fetchProfile = async () => {
    const token = localStorage.getItem('accessToken');
    const response = await get_api('/api/user-profile/');
    console.log(response.data);
    setprofiledata(response.data);
  }

  useEffect(() => {
    fetchBots();
    fetchProfile();
  }, []);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleCreateBot = async () => {
    const token = localStorage.getItem('accessToken');
    await post_api(
      '/api/bots/',
      { name: botName, description: botDescription, bot_name: botbotName },

    );
    fetchBots();
    handleClose();
  };

  

  const handleDeleteBot = async (id) => {
    const token = localStorage.getItem('accessToken');
    await delete_api(`/api/bots/${id}/`);
    fetchBots();
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography variant="h5" gutterBottom>
        Dashboard
      </Typography>
      <Button variant="contained" color="primary" onClick={handleOpen}>
        Create Bot
      </Button>
      </Box>
      <List>
        {bots.length ? bots.map((bot) => (
          <Card sx={{ mb: 2 }}>
          <ListItem key={bot.id}  secondaryAction={
            <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteBot(bot.id)}>
              <DeleteIcon />
            </IconButton>
          }>
            <ListItemText
              primary={
                <Link to={`/bots/${bot.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                  {bot.name}
                </Link>
              }
              secondary={bot.description}
            />
          </ListItem>
          </Card>
        ))
        :
        <Typography variant="body1" color="textSecondary">
          No bots found, Please Create a bot
        </Typography>
        
        }
      </List>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Create Bot</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            fullWidth
            margin="normal"
            value={botName}
            onChange={(e) => setBotName(e.target.value)}
          />
          <TextField
            label="Bot Name"
            fullWidth
            margin="normal"
            value={botbotName}
            onChange={(e) => setBotbotName(e.target.value)}
          />
          <TextField
            label="Description"
            fullWidth
            margin="normal"
            value={botDescription}
            onChange={(e) => setBotDescription(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleCreateBot}>Create</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Dashboard;
