// src/pages/BotDetail.js

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
    Typography,
    Box,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    IconButton,
    Grid,
    Card,
    CardContent,
    CircularProgress,
    Checkbox,
    FormControlLabel,
    Collapse,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    InputBase,
    Select,
    MenuItem,
    FormControl,
} from '@mui/material';
import Link from '@mui/material/Link';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DescriptionIcon from '@mui/icons-material/Description';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import ImageIcon from '@mui/icons-material/Image';
import LanguageIcon from '@mui/icons-material/Language';
import SearchIcon from '@mui/icons-material/Search';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { get_api, post_api, delete_api, upload_api } from '../utils/api';
import { SaasBot } from '../utils/chatbot/chatbot';
import { useDropzone } from 'react-dropzone';
import theme from '../theme';

const BotDetail = () => {
    const { id } = useParams();
    const [bot, setBot] = useState(null);
    const [tools, setTools] = useState([]);
    const [files, setFiles] = useState([]);
    const [urls, setUrls] = useState([]);
    const [openToolDialog, setOpenToolDialog] = useState(false);
    const [toolData, setToolData] = useState({
        name: '',
        api_endpoint: '',
        variables: '',
        body_format: '',
        description: '',
        tool_type: '',
    });
    const [filesToUpload, setFilesToUpload] = useState([]);
    const [openFileDialog, setOpenFileDialog] = useState(false);
    const [openUrlDialog, setOpenUrlDialog] = useState(false);
    const [urlData, setUrlData] = useState({
        url: 'https://',
        recursive: false,
    });
    const [viewType, setViewType] = useState('grid');
    const [searchQuery, setSearchQuery] = useState('');
    const [expandedSections, setExpandedSections] = useState({
        actions: true,
        documents: true,
        urls: true,
    });
    const [allFilesLoaded, setAllFilesLoaded] = useState(true);
    const [allUrlsLoaded, setAllUrlsLoaded] = useState(true);

    const token = localStorage.getItem('accessToken');

    useEffect(() => {
        let filePollingInterval;
        let urlPollingInterval;
    
        if (!allFilesLoaded) {
            filePollingInterval = setInterval(() => {
                fetchFiles();
            }, 10000);
        }
    
        if (!allUrlsLoaded) {
            urlPollingInterval = setInterval(() => {
                fetchUrls();
            }, 10000);
        }
    
        return () => {
            if (filePollingInterval) {
                clearInterval(filePollingInterval);
            }
            if (urlPollingInterval) {
                clearInterval(urlPollingInterval);
            }
        };
    }, [allFilesLoaded, allUrlsLoaded]);

    const handleToggleExpand = (section) => {
        setExpandedSections({
            ...expandedSections,
            [section]: !expandedSections[section],
        });
    };

    const onDrop = (acceptedFiles) => {
        setFilesToUpload(acceptedFiles);
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'text/plain': ['.txt'],
            'application/pdf': ['.pdf'],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
            'audio/mpeg': ['.mp3'],
            'audio/mp3': ['.mp3'],
        },
    });

    const handleUploadFiles = async () => {
        try {
            await Promise.all(
                filesToUpload.map((file) => {
                    const formData = new FormData();
                    formData.append('file', file);

                    return upload_api(`/api/bots/${id}/files/`, formData);
                })
            );
            fetchFiles();
            setFilesToUpload([]);
            handleCloseFileDialog();
        } catch (error) {
            console.error('File upload error:', error);
            alert('Error uploading files. Please try again.');
        }
    };

    const fetchBotDetails = async () => {
        const response = await get_api(`/api/bots/${id}/`);
        setBot(response.data);
    };

    const fetchTools = async () => {
        const response = await get_api(`/api/bots/${id}/tools/`);
        setTools(response.data);
    };

    const fetchFiles = async () => {
        const response = await get_api(`/api/bots/${id}/files/`);
        setFiles(response.data);
        const allLoaded = response.data.every((file) => file.ingested || file.failed);
        setAllFilesLoaded(allLoaded)
    };

    const fetchUrls = async () => {
        const response = await get_api(`/api/bots/${id}/urls/`);
        setUrls(response.data);
        const allLoaded = response.data.every((url) => url.ingested || url.failed);
        setAllUrlsLoaded(allLoaded);
    };

    useEffect(() => {
        fetchBotDetails();
        fetchTools();
        fetchFiles();
        fetchUrls();
    }, [id]);

    const handleOpenToolDialog = () => setOpenToolDialog(true);
    const handleCloseToolDialog = () => setOpenToolDialog(false);

    const handleCreateTool = async () => {
        await post_api(`/api/bots/${id}/tools/`, {
            ...toolData,
            variables: toolData.variables ? JSON.parse(toolData.variables) : null,
            body_format: toolData.body_format ? JSON.parse(toolData.body_format) : null,
        });
        fetchTools();
        handleCloseToolDialog();
    };

    const handleDeleteTool = async (toolId) => {
        await delete_api(`/api/bots/${id}/tools/${toolId}/`);
        fetchTools();
    };

    const handleOpenFileDialog = () => setOpenFileDialog(true);
    const handleCloseFileDialog = () => setOpenFileDialog(false);

    const handleDeleteFile = async (fileId) => {
        await delete_api(`/api/bots/${id}/files/${fileId}/`);
        fetchFiles();
    };

    const handleOpenUrlDialog = () => setOpenUrlDialog(true);
    const handleCloseUrlDialog = () => setOpenUrlDialog(false);

    const handleCreateUrl = async () => {
        try {
            await post_api(`/api/bots/${id}/urls/`, { ...urlData });
            fetchUrls();
            handleCloseUrlDialog();
            setUrlData({ url: '', recursive: false });
        } catch (error) {
            console.error('Error adding URL:', error);
            alert('Error adding URL. Please try again.');
        }
    };

    const handleDeleteUrl = async (urlId) => {
        try {
            await delete_api(`/api/bots/${id}/urls/`, { url_id: urlId });
            fetchUrls();
        } catch (error) {
            console.error('Error deleting URL:', error);
            alert('Error deleting URL. Please try again.');
        }
    };

    const getFileIcon = (filename) => {
        const extension = filename.split('.').pop().toLowerCase();
        switch (extension) {
            case 'pdf':
                return <PictureAsPdfIcon fontSize="large" />;
            case 'docx':
            case 'txt':
                return <DescriptionIcon fontSize="large" />;
            case 'mp3':
                return <AudiotrackIcon fontSize="large" />;
            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'gif':
                return <ImageIcon fontSize="large" />;
            default:
                return <InsertDriveFileIcon fontSize="large" />;
        }
    };

    const getUrlIcon = () => {
        return <LanguageIcon fontSize="large" />;
    };

    const filteredFiles = files.filter((file) =>
        file.file.toLowerCase().includes(searchQuery.toLowerCase())
    );
    const filteredUrls = urls.filter((url) =>
        url.url.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const renderFiles = () => {
        return viewType === 'grid' ? (
            <Grid container spacing={2}>
                {filteredFiles.map((file) => (
                    <Grid item xs={6} sm={4} md={3} key={file.id}>
                        <Card>
                            <CardContent sx={{ position: 'relative' }}>
                                {file.failed ? (
                                    <Typography color="error">Ingestion Failed</Typography>
                                ) : (
                                    !file.ingested && (
                                        <CircularProgress
                                            size={40}
                                            sx={{
                                                position: 'absolute',
                                                top: '40%',
                                                left: '40%',
                                            }}
                                        />
                                    )
                                )}
                                <Box sx={{ textAlign: 'center', opacity: file.ingested ? 1 : 0.5 }}>
                                    {getFileIcon(file.file)}
                                    <Typography variant="body2" noWrap>
                                        {file.file.split('/').pop()}
                                    </Typography>
                                </Box>
                                <IconButton
                                    size="small"
                                    sx={{ position: 'absolute', top: 0, right: 0 }}
                                    onClick={() => handleDeleteFile(file.id)}
                                    disabled={!file.ingested && !file.failed}
                                >
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        ) : (
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>File Name</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredFiles.map((file) => (
                            <TableRow key={file.id}>
                                <TableCell>{file.file.split('/').pop()}</TableCell>
                                <TableCell>
                                    {file.failed ? (
                                        <Typography color="error">Failed</Typography>
                                    ) : file.ingested ? (
                                        'Ingested'
                                    ) : (
                                        <CircularProgress size={20} />
                                    )}
                                </TableCell>
                                <TableCell>
                                    <IconButton
                                        onClick={() => handleDeleteFile(file.id)}
                                        disabled={!file.ingested && !file.failed}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    const renderUrls = () => {
        return viewType === 'grid' ? (
            <Grid container spacing={2}>
                {filteredUrls.map((url) => (
                    <Grid item xs={6} sm={4} md={3} key={url.id}>
                        <Card>
                            <CardContent sx={{ position: 'relative' }}>
                                {url.failed ? (
                                    <Typography color="error">Ingestion Failed</Typography>
                                ) : (
                                    !url.ingested && (
                                        <CircularProgress
                                            size={40}
                                            sx={{
                                                position: 'absolute',
                                                top: '40%',
                                                left: '40%',
                                            }}
                                        />
                                    )
                                )}
                                <Box sx={{ textAlign: 'center', opacity: url.ingested ? 1 : 0.5 }}>
                                    {getUrlIcon()}
                                    <Typography variant="body2" noWrap>
                                        <Link href={url.url} target="_blank" rel="noopener noreferrer">
                                            {url.url}
                                        </Link>
                                    </Typography>
                                </Box>
                                <IconButton
                                    size="small"
                                    sx={{ position: 'absolute', top: 0, right: 0 }}
                                    onClick={() => handleDeleteUrl(url.id)}
                                    disabled={!url.ingested && !url.failed}
                                >
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        ) : (
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>URL</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredUrls.map((url) => (
                            <TableRow key={url.id}>
                                <TableCell>
                                    <Link href={url.url} target="_blank" rel="noopener noreferrer">
                                        {url.url}
                                    </Link>
                                </TableCell>
                                <TableCell>
                                    {url.failed ? (
                                        <Typography color="error">Failed</Typography>
                                    ) : url.ingested ? (
                                        'Ingested'
                                    ) : (
                                        <CircularProgress size={20} />
                                    )}
                                </TableCell>
                                <TableCell>
                                    <IconButton
                                        onClick={() => handleDeleteUrl(url.id)}
                                        disabled={!url.ingested && !url.failed}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    return (
        <Box sx={{ p: { xs: 2, md: 4 } }}>
            {/* Bot Details Card */}
            <Card sx={{ mb: 4 }}>
                <CardContent>
                    <Typography variant="h5" gutterBottom>
                        {bot?.name}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        {bot?.description}
                    </Typography>
                    <Box display="flex" alignItems="center">
                        <TextField
                            label="API Key"
                            fullWidth
                            margin="normal"
                            value={bot?.uuid}
                            InputProps={{
                                readOnly: true,
                            }}
                            InputLabelProps={{ shrink: true }}

                        />
                        <IconButton
                            onClick={() => {
                                navigator.clipboard.writeText(bot?.uuid);
                                alert('API Key copied to clipboard');
                            }}
                            sx={{ ml: 1 }}
                        >
                            <ContentCopyIcon />
                        </IconButton>
                    </Box>
                </CardContent>
            </Card>

            {/* Search and View Controls */}
            <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ mb: 2 }}>
                <Box display="flex" alignItems="center">
                    <InputBase
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        startAdornment={<SearchIcon />}
                        sx={{ border: '1px solid #ccc', borderRadius: '4px', padding: '0 10px' }}
                    />
                </Box>
                <Box>
                    <IconButton onClick={() => setViewType('grid')}>
                        <ViewModuleIcon color={viewType === 'grid' ? 'primary' : 'inherit'} />
                    </IconButton>
                    <IconButton onClick={() => setViewType('table')}>
                        <ViewListIcon color={viewType === 'table' ? 'primary' : 'inherit'} />
                    </IconButton>
                </Box>
            </Box>

            {/* Actions Section */}
            <Box>
                <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ mt: 4, border: '1px solid #ccc', borderRadius: '8px', padding: 2 }}>
                    <Box display="flex" alignItems="center">
                        <Typography variant="h5" sx={{ mr: 1 }}>Actions (Under development)</Typography>
                        <IconButton color="primary" onClick={handleOpenToolDialog}>
                            <AddCircleIcon />
                        </IconButton>
                    </Box>
                    <IconButton onClick={() => handleToggleExpand('actions')}>
                        <ExpandMoreIcon style={{ transform: expandedSections.actions ? 'rotate(180deg)' : 'rotate(0deg)' }} />
                    </IconButton>
                </Box>
                <Collapse in={expandedSections.actions}>
                    <Grid container spacing={2} sx={{ mt: 2 }}>
                        {tools.map((tool) => (
                            <Grid item xs={12} sm={6} md={4} key={tool.id}>
                                <Card>
                                    <CardContent>
                                        <Typography variant="h6">{tool.name}</Typography>
                                        <Typography variant="body2">{tool.description}</Typography>
                                    </CardContent>
                                    <Box sx={{ textAlign: 'right' }}>
                                        <IconButton onClick={() => handleDeleteTool(tool.id)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Box>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Collapse>
            </Box>

            {/* Documents Section */}
            <Box sx={{ mt: 4 }}>
                <Box display="flex"  alignItems="center" justifyContent="space-between" sx={{ mt: 4, border: '1px solid #ccc', borderRadius: '8px', padding: 2 ,backgroundColor: theme.palette.background.default}}>
                    <Box display="flex" alignItems="center">
                        <Typography variant="h5" sx={{ mr: 1 }}>Documents</Typography>
                        <IconButton color="primary" onClick={handleOpenFileDialog}>
                            <AddCircleIcon />
                        </IconButton>
                    </Box>
                    <IconButton onClick={() => handleToggleExpand('documents')}>
                        <ExpandMoreIcon style={{ transform: expandedSections.documents ? 'rotate(180deg)' : 'rotate(0deg)' }} />
                    </IconButton>
                </Box>

                <Collapse in={expandedSections.documents} sx={{ mt: 2 }}>
                    {renderFiles()}
                </Collapse>
            </Box>

            {/* URLs Section */}
            <Box sx={{ mt: 4 }}>
                <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ mt: 4, border: '1px solid #ccc', borderRadius: '8px', padding: 2 }}>
                    <Box display="flex" alignItems="center">
                        <Typography variant="h5" sx={{ mr: 1 }}>URLs</Typography>
                        <IconButton color="primary" onClick={handleOpenUrlDialog}>
                            <AddCircleIcon />
                        </IconButton>
                    </Box>
                    <IconButton onClick={() => handleToggleExpand('urls')}>
                        <ExpandMoreIcon style={{ transform: expandedSections.urls ? 'rotate(180deg)' : 'rotate(0deg)' }} />
                    </IconButton>
                </Box>

                <Collapse in={expandedSections.urls} sx={{ mt: 2 }}>
                    {renderUrls()}
                </Collapse>
            </Box>

            {/* Tool Dialog */}
            <Dialog open={openToolDialog} onClose={handleCloseToolDialog}>
                <DialogTitle>Add Tool</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Name"
                        fullWidth
                        margin="normal"
                        value={toolData.name}
                        onChange={(e) => setToolData({ ...toolData, name: e.target.value })}
                    />
                    <TextField
                        label="API Endpoint"
                        fullWidth
                        margin="normal"
                        value={toolData.api_endpoint}
                        onChange={(e) => setToolData({ ...toolData, api_endpoint: e.target.value })}
                    />
                    <TextField
                        label="Variables (JSON)"
                        fullWidth
                        margin="normal"
                        value={toolData.variables}
                        onChange={(e) => setToolData({ ...toolData, variables: e.target.value })}
                    />
                    <TextField
                        label="Body Format (JSON)"
                        fullWidth
                        margin="normal"
                        value={toolData.body_format}
                        onChange={(e) => setToolData({ ...toolData, body_format: e.target.value })}
                    />
                    <TextField
                        label="Description"
                        fullWidth
                        margin="normal"
                        value={toolData.description}
                        onChange={(e) => setToolData({ ...toolData, description: e.target.value })}
                    />
                    <TextField
                        label="Tool Type"
                        fullWidth
                        margin="normal"
                        value={toolData.tool_type}
                        onChange={(e) => setToolData({ ...toolData, tool_type: e.target.value })}
                        placeholder="search, create, delete, get"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseToolDialog}>Cancel</Button>
                    <Button onClick={handleCreateTool}>Add</Button>
                </DialogActions>
            </Dialog>

            {/* File Dialog */}
            <Dialog open={openFileDialog} onClose={handleCloseFileDialog} fullWidth maxWidth="sm">
                <DialogTitle>Upload Documents</DialogTitle>
                <DialogContent>
                    <div
                        {...getRootProps()}
                        style={{
                            border: '2px dashed #cccccc',
                            borderRadius: '5px',
                            padding: '20px',
                            textAlign: 'center',
                            cursor: 'pointer',
                            color: '#cccccc',
                        }}
                    >
                        <input {...getInputProps()} />
                        {isDragActive ? (
                            <Typography>Drop the files here...</Typography>
                        ) : (
                            <Typography>Drag and drop files here, or click to select files</Typography>
                        )}
                        <Typography variant="caption">
                            Allowed file types: .txt, .pdf, .docx, .mp3
                        </Typography>
                    </div>
                    <Box sx={{ mt: 2 }}>
                        {filesToUpload.length > 0 && (
                            <Typography variant="subtitle1">
                                Files to be uploaded:
                            </Typography>
                        )}
                        <ul>
                            {filesToUpload.map((file, index) => (
                                <li key={index}>{file.name}</li>
                            ))}
                        </ul>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseFileDialog}>Cancel</Button>
                    <Button onClick={handleUploadFiles} disabled={filesToUpload.length === 0}>
                        Upload
                    </Button>
                </DialogActions>
            </Dialog>

            {/* URL Dialog */}
            <Dialog open={openUrlDialog} onClose={handleCloseUrlDialog}>
                <DialogTitle>Add URL</DialogTitle>
                <DialogContent>
                    <TextField
                        label="URL"
                        fullWidth
                        margin="normal"
                        value={urlData.url}
                        onChange={(e) => setUrlData({ ...urlData, url: e.target.value })}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={urlData.recursive}
                                onChange={(e) =>
                                    setUrlData({ ...urlData, recursive: e.target.checked })
                                }
                            />
                        }
                        label="Add Linked URL's On This Page"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseUrlDialog}>Cancel</Button>
                    <Button onClick={handleCreateUrl}>Add</Button>
                </DialogActions>
            </Dialog>

            <SaasBot
                api_key={bot?.uuid}
                topBarColor="#ff5722"
                backgroundColor="#ffffff"
                bubbleColor="#ff9800"
            />
        </Box>
    );
};

export default BotDetail;
