import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'light', // Alternatively, use 'dark' for a dark mode theme
    primary: {
      main: '#0047AB', // Deep blue, representing trust and reliability
      contrastText: '#ffffff', // White text for buttons and primary elements
    },
    secondary: {
      main: '#9c27b0', // Purple to signify creativity and highlight important actions
      contrastText: '#ffffff', // White text for secondary elements
    },
    background: {
      default: '#f0f4f8', // Light grayish-blue for a modern, clean background
      paper: '#ffffff', // White background for cards and other paper elements
    },
    text: {
      primary: '#333333', // Dark grey for primary text, easy on the eyes
      secondary: '#555555', // Lighter grey for secondary text
    },
    success: {
      main: '#28a745', // Green for success messages
    },
    warning: {
      main: '#f39c12', // Orange for warnings, to draw attention without alarming users
    },
    error: {
      main: '#d32f2f', // Red for errors, representing urgency
    },
  },
  typography: {
    fontFamily: 'Inter, Roboto, Arial, sans-serif', // Modern, clean fonts
    h1: {
      fontSize: '2.5rem',
      fontWeight: 700,
      color: '#0047AB', // Primary color to tie in branding
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 600,
      color: '#333333',
    },
    h3: {
      fontSize: '1.75rem',
      fontWeight: 500,
    },
    body1: {
      fontSize: '1rem',
      color: '#555555',
      lineHeight: 1.6,
    },
    button: {
      textTransform: 'none', // Disable uppercase for a more professional look
      fontWeight: 600,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '12px', // Soft, rounded corners for a friendly look
          padding: '8px 16px', // Comfortable padding for buttons
          boxShadow: 'none', // Remove shadows for a modern, flat design
          ':hover': {
            boxShadow: '0 4px 10px rgba(0, 71, 171, 0.2)', // Subtle hover effect
          },
        },
        containedPrimary: {
          backgroundColor: '#0047AB',
          ':hover': {
            backgroundColor: '#00357A', // Slightly darker on hover
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffffff',
          color: '#0047AB',
          boxShadow: '0 1px 4px rgba(0, 0, 0, 0.1)', // Minimal shadow for elegance
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '16px', // Rounded corners for a friendly user experience
          boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
          padding: '24px',
        },
      },
    },
  },
});

export default theme;
