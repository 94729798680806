

import axios from 'axios';

const token = localStorage.getItem('accessToken');

export const get_api = async (url) => {
    const token = localStorage.getItem('accessToken');

    if (!token) {
        // Redirect to login page
        window.location.href = '/login';
        return;
    }

    try {
        const response = await axios.get(url, {
            headers: { Authorization: `Bearer ${token}` },
        });

        return response;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            // Redirect to login page
            window.location.href = '/login';
        } else {
            throw error;
        }
    }
}

export const post_api = async (url, data) => {
    const token = localStorage.getItem('accessToken');

    if (!token) {
        // Redirect to login page
        window.location.href = '/login';
        return;
    }

    try {
        const response = await axios.post(url, data, {
            headers: { Authorization: `Bearer ${token}` },
        });

        return response;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            // Redirect to login page
            window.location.href = '/login';
        } else {
            throw error;
        }
    }
}

export const delete_api = async (url,data=null) => {
    const token = localStorage.getItem('accessToken');

    if (!token) {
        // Redirect to login page
        window.location.href = '/login';
        return;
    }

    try {
        if (data) {
            const response = await axios.delete(url, {
                headers: { Authorization: `Bearer ${token}` },
                data: data,
            });

            return response;
        }
        else {
            const response = await axios.delete(url, {
                headers: { Authorization: `Bearer ${token}` },
            });

            return response;
        }

    
    } catch (error) {
        if (error.response && error.response.status === 401) {
            // Redirect to login page
            window.location.href = '/login';
        } else {
            throw error;
        }
    }
}


export const upload_api = async (url, formData) => {
    const token = localStorage.getItem('accessToken');

    if (!token) {
        // Redirect to login page
        window.location.href = '/login';
        return;
    }

    try {
        const response = await axios.post(url, formData, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            },
        });

        return response;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            // Redirect to login page
            window.location.href = '/login';
        } else {
            throw error;
        }
    }
}