import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import { useTheme } from '@mui/system';
import { Link } from 'react-router-dom';

export default function ContactUs() {
  const theme = useTheme();
  // const logos = theme.palette.mode === 'light' ? darkLogos : whiteLogos;

  return (
    <Container
      id="contactus"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { xs:'100%',sm: '100%', md: '60%', lg: '60%', xl: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <Typography
          component="h2"
          variant="h4"
          gutterBottom
          sx={{ color: 'text.primary' }}
        >
          Contact Us
        </Typography>

        <Typography variant="body1" sx={{ color: 'text.secondary' }}>
          We are here to help you with any queries you may have. Please feel free to reach out to us at the following email address, phone number, or visit us at our office.
        </Typography>
        <Typography variant="h2" sx={{ color: 'text.secondary' }}>
          <Link href="mailto:contact@saasbotai.com" color="inherit">
            contact@saasbotai.com
          </Link>
        </Typography>

        <Typography variant="body1" sx={{ color: 'text.secondary', mt: 2 }}>
          Phone: 
        </Typography>
        <Typography variant="h5" sx={{ color: 'text.secondary' }}>
          <Link href="tel:+1234567890" color="inherit">
          +91 9952045932
          </Link>
        </Typography>

        <Typography variant="body1" sx={{ color: 'text.secondary', mt: 2 }}>
          Address: 
        </Typography>
        <Typography variant="h6" sx={{ color: 'text.secondary' }}>
        46/1, 1st Floor, Swarnambika Layout, Ramnagar
        <br />
        Coimbatore, Tamil Nadu, India, 641009
        </Typography>
      </Box>
      <Grid container spacing={2}>
        
      </Grid>
    </Container>
  );
}
