import React from 'react';
import { Container, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import HomeIcon from '@mui/icons-material/Home';

function PrivacyPolicy() {
    return (
        <Container maxWidth="md" sx={{ marginTop: 4, marginBottom: 4 }}>
            <Button variant="outlined" onClick={() => window.location.href = '/'}>
                <HomeIcon />
            </Button>
            <Typography variant="h4" gutterBottom>
                Privacy Policy
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
                Your privacy is important to us. This Privacy Policy explains how SaasbotAI collects, uses, discloses, and safeguards your information.
            </Typography>

            {/* Section 1: Information We Collect */}
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">1. Information We Collect</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="body1">
                        We collect the following types of information:
                        <ul>
                            <li>
                                Personal Information: When you create an account, we collect personal information such as your name, email address, and billing information.
                            </li>
                            <li>
                                Usage Data: We collect information on how you interact with SaasbotAI, including IP address, browser type, pages visited, and time spent on our site.
                            </li>
                            <li>
                                Content Data: When using our services, we may collect content that you upload, such as documents, URLs, and voice clips.
                            </li>
                        </ul>
                    </Typography>
                </AccordionDetails>
            </Accordion>

            {/* Section 2: How We Use Your Information */}
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">2. How We Use Your Information</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="body1">
                        We use the information we collect for the following purposes:
                        <ul>
                            <li>To Provide Services: To create and manage your account, and provide chatbot integration and support.</li>
                            <li>To Improve Our Services: To analyze usage patterns, understand user needs, and improve our services.</li>
                            <li>To Communicate With You: To send you updates, respond to your inquiries, and provide support.</li>
                            <li>To Ensure Security: To monitor for fraud, security threats, and prevent unauthorized access.</li>
                        </ul>
                    </Typography>
                </AccordionDetails>
            </Accordion>

            {/* Section 3: Information Sharing and Disclosure */}
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">3. Information Sharing and Disclosure</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="body1">
                        We do not sell or rent your personal information to third parties. We may share your information in the following circumstances:
                        <ul>
                            <li>Service Providers: We may share your data with trusted service providers who help us operate our platform (e.g., payment processing, customer support).</li>
                            <li>Legal Requirements: We may disclose information if required by law, such as in response to a subpoena, or if we believe it is necessary to protect the rights, property, or safety of SaasbotAI or others.</li>
                            <li>Business Transfers: In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred as part of that transaction.</li>
                        </ul>
                    </Typography>
                </AccordionDetails>
            </Accordion>

            {/* Section 4: Cookies and Tracking Technologies */}
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">4. Cookies and Tracking Technologies</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="body1">
                        SaasbotAI uses cookies and similar technologies to enhance your experience. Cookies help us remember your preferences and understand how you use our site. You can manage cookie settings in your browser, but disabling cookies may affect the functionality of our services.
                    </Typography>
                </AccordionDetails>
            </Accordion>

            {/* Section 5: Data Security */}
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">5. Data Security</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="body1">
                        We use appropriate technical and organizational measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no internet-based service is 100% secure, and we cannot guarantee the absolute security of your data.
                    </Typography>
                </AccordionDetails>
            </Accordion>

            {/* Section 6: Your Data Rights */}
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">6. Your Data Rights</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="body1">
                        Depending on your location, you may have the following rights regarding your data:
                        <ul>
                            <li>Access: Request a copy of your personal information that we hold.</li>
                            <li>Correction: Request corrections to inaccurate or incomplete information.</li>
                            <li>Deletion: Request deletion of your personal information, subject to legal requirements.</li>
                            <li>Restriction: Request that we restrict processing of your personal information under certain circumstances.</li>
                        </ul>
                        To exercise these rights, please contact us at privacy@saasbotai.com.
                    </Typography>
                </AccordionDetails>
            </Accordion>

            {/* Section 7: Data Retention */}
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">7. Data Retention</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="body1">
                        We retain your personal information for as long as necessary to provide our services and fulfill the purposes outlined in this Privacy Policy. We may also retain information for legal, security, and compliance purposes.
                    </Typography>
                </AccordionDetails>
            </Accordion>

            {/* Section 8: Children's Privacy */}
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">8. Children's Privacy</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="body1">
                        SaasbotAI is not intended for use by children under the age of 16. We do not knowingly collect personal information from children. If we learn that we have collected such information, we will take steps to delete it promptly. If you believe we may have collected information from a child, please contact us at privacy@saasbotai.com.
                    </Typography>
                </AccordionDetails>
            </Accordion>

            {/* Section 9: Changes to This Privacy Policy */}
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">9. Changes to This Privacy Policy</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="body1">
                        We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will provide notice of any significant changes by posting the new policy on our website. Your continued use of SaasbotAI after the changes take effect constitutes your acceptance of the new policy.
                    </Typography>
                </AccordionDetails>
            </Accordion>

            {/* Section 10: Contact Information */}
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">10. Contact Information</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="body1">
                        If you have questions or concerns about this Privacy Policy or our data practices, please contact us:
                        <br />
                        <br />
                        <strong>Email:</strong> contact@saasbotai.com
                        
                        <br />
                        <strong>Address:</strong> Coimbatore, Tamil Nadu, India
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </Container>
    );
}

export default PrivacyPolicy;
