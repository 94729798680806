// src/App.js

import React from 'react';
import { Routes, Route,useLocation } from 'react-router-dom';
import { Container } from '@mui/material';

import Navbar from './components/Navbar';
import Home from './pages/Home';
import Login from './pages/Login';
import Register from './pages/Register';
import Dashboard from './pages/Dashboard';
import BotDetail from './pages/BotDetail';
import TermsAndConditions from './pages/TermsAndConditions';
import PrivacyPolicy from './pages/PrivacyPolicy';
import PrivateRoute from './components/PrivateRoute';
import PlansPage from './pages/plans';

function App() {
  const location = useLocation();

  // Determine if the current page is the Home page
  const isHomePage = ['/','/terms_and_conditions','/privacy_policy'].includes(location.pathname);

  return (
    <>
      {!isHomePage && <Navbar />}
      {!isHomePage ? (
        <Container sx={{ mt: 4 }}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route
              path="/dashboard"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/bots/:id"
              element={
                <PrivateRoute>
                  <BotDetail />
                </PrivateRoute>
              }
            />
            <Route path="/plans" element={<PlansPage />} />
          </Routes>
        </Container>
      ) : (
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/terms_and_conditions" element={<TermsAndConditions />} />
          <Route path="/privacy_policy" element={<PrivacyPolicy />} />
        </Routes>
      )}
    </>
  );
}

export default App;