import React from 'react';
import { Container, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';

function TermsAndConditions() {


    return (
        <Container maxWidth="md" sx={{ marginTop: 4, marginBottom: 4 }}>
            <Button variant="outlined" onClick={() => window.location.href = '/'}>
                <HomeIcon />
            </Button>

            <Typography variant="h4" gutterBottom>
                Terms and Conditions
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
                Welcome to SaasbotAI! Please read these terms and conditions carefully before using our services.
            </Typography>

            {/* Section 1: Acceptance of Terms */}
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">1. Acceptance of Terms</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="body1">
                        By accessing or using SaasbotAI, you agree to be bound by these Terms and Conditions, as well as our Privacy Policy. If you do not agree to these terms, you may not use our services. We reserve the right to update or modify these terms at any time, and it is your responsibility to review them periodically. Continued use of SaasbotAI after any changes implies acceptance of the new terms.
                    </Typography>
                </AccordionDetails>
            </Accordion>

            {/* Section 2: Use of Service */}
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">2. Use of Service</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="body1">
                        SaasbotAI provides tools to create and integrate chatbots into your application. You are granted a limited, non-exclusive, non-transferable license to use our platform for lawful purposes. You agree not to misuse the services, including but not limited to unauthorized access, tampering, data scraping, or attempting to disrupt the service in any way. Any unauthorized use may lead to suspension or termination of access to SaasbotAI.
                    </Typography>
                </AccordionDetails>
            </Accordion>

            {/* Section 3: User Accounts */}
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">3. User Accounts</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="body1">
                        To access certain features of SaasbotAI, you must create an account. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account. SaasbotAI will not be liable for any losses caused by unauthorized use of your account.
                    </Typography>
                </AccordionDetails>
            </Accordion>

            {/* Section 4: Subscription and Payment */}
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">4. Subscription and Payment</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="body1">
                        SaasbotAI offers various subscription plans, each with different features. You agree to pay the fees associated with the plan you choose. Payments are non-refundable except as required by applicable law. We reserve the right to modify our pricing and will notify you of any changes. Continued use of the service after any price change implies acceptance of the new pricing.
                    </Typography>
                </AccordionDetails>
            </Accordion>

            {/* Section 5: Intellectual Property */}
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">5. Intellectual Property</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="body1">
                        All content, features, and functionality of SaasbotAI, including but not limited to text, graphics, logos, and software, are the property of SaasbotAI or its licensors and are protected by copyright, trademark, and other intellectual property laws. You are not permitted to reproduce, distribute, or create derivative works of any part of our service without prior written consent.
                    </Typography>
                </AccordionDetails>
            </Accordion>

            {/* Section 6: User Content */}
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">6. User Content</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="body1">
                        By using SaasbotAI, you may upload, submit, or otherwise make available certain content, including text, documents, URLs, and voice clips. You retain ownership of your content, but you grant SaasbotAI a non-exclusive, royalty-free license to use, reproduce, and process your content solely for the purpose of providing our services. You represent that you have all necessary rights to submit such content and that it does not infringe on any third-party rights.
                    </Typography>
                </AccordionDetails>
            </Accordion>

            {/* Section 7: Limitation of Liability */}
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">7. Limitation of Liability</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="body1">
                        SaasbotAI is provided on an "as is" and "as available" basis. We make no warranties, either express or implied, regarding the service, including but not limited to the accuracy, reliability, or availability of the service. In no event shall SaasbotAI, its officers, directors, employees, or agents be liable for any direct, indirect, incidental, consequential, or punitive damages arising from your use of, or inability to use, the service.
                    </Typography>
                </AccordionDetails>
            </Accordion>

            {/* Section 8: Termination */}
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">8. Termination</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="body1">
                        We may suspend or terminate your access to SaasbotAI at our discretion, without notice, for conduct that we believe violates these Terms or is harmful to other users or the service. You may terminate your account at any time by contacting support at support@saasbotai.com. Upon termination, all licenses granted to you under these terms will cease immediately.
                    </Typography>
                </AccordionDetails>
            </Accordion>

            {/* Section 9: Privacy Policy */}
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">9. Privacy Policy</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="body1">
                        Your use of SaasbotAI is also governed by our Privacy Policy, which explains how we collect, use, and protect your information. By using the service, you consent to the collection and use of information as described in the Privacy Policy.
                    </Typography>
                </AccordionDetails>
            </Accordion>

            {/* Section 10: Governing Law */}
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">10. Governing Law</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="body1">
                        These terms shall be governed by and construed in accordance with the laws of the jurisdiction in which SaasbotAI operates. Any disputes arising under these terms will be resolved in the courts of the applicable jurisdiction, and you consent to the exclusive jurisdiction of such courts.
                    </Typography>
                </AccordionDetails>
            </Accordion>

            {/* Section 11: Indemnification */}
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">11. Indemnification</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="body1">
                        You agree to indemnify, defend, and hold harmless SaasbotAI, its officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, and expenses, including without limitation, legal and accounting fees, arising out of your use of the service, your violation of these terms, or your infringement of any third-party rights.
                    </Typography>
                </AccordionDetails>
            </Accordion>

            {/* Section 12: Contact Information */}
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">12. Contact Information</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="body1">
                        If you have any questions about these Terms and Conditions or need further assistance, please contact us:
                        <br />
                        <br />
                        <strong>Email:</strong> contact@saasbotai.com
                        <br />
                        <strong>Address:</strong> Coimbadtore, Tamil Nadu, India
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </Container>
    );
}

export default TermsAndConditions;
