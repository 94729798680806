import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import AppAppBar from '../components/langding_page/AppAppBar';
import Hero from '../components/langding_page/Hero';
import LogoCollection from '../components/langding_page/LogoCollection';
import Highlights from '../components/langding_page/Highlights';
import Pricing from '../components/langding_page/Pricing';
import Features from '../components/langding_page/Features';
import Testimonials from '../components/langding_page/Testimonials';
import FAQ from '../components/langding_page/FAQ';
import Footer from '../components/langding_page/Footer';
import ContactUs from '../components/langding_page/ContactUs';
import { Helmet } from 'react-helmet';



export default function Home() {
  const [mode, setMode] = React.useState('light');
  // This code only runs on the client side, to determine the system color preference
  React.useEffect(() => {
    // Check if there is a preferred mode in localStorage
    const savedMode = localStorage.getItem('themeMode');
    if (savedMode) {
      setMode(savedMode);
    } else {
      // If no preference is found, it uses system preference
      const systemPrefersDark = window.matchMedia(
        '(prefers-color-scheme: dark)',
      ).matches;
      setMode(systemPrefersDark ? 'dark' : 'light');
    }
  }, []);



  return (
        <>
        <Helmet>
        <title>SaasbotAI - Seamless Custom AI Chatbot Integration in Minutes</title>
        <meta name="description" content="Integrate a powerful LLM chatbot into your website or app effortlessly. SaasbotAI offers an easy, no-code way to build and deploy custom AI assistants." />
        <meta name="keywords" content="AI chatbot integration, custom chatbot, LLM chatbot, easy chatbot integration" />
        <meta property="og:title" content="SaasbotAI - Seamless Custom AI Chatbot Integration in Minutes" />
        <meta property="og:description" content="Easily integrate an LLM chatbot into your app with SaasbotAI. No coding required." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://saasbotai.com/" />
        <meta property="og:image" content="http://saasbotai.com/static/media/saasbotai.7e81c835eb6af18e9dd9.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="SaasbotAI - Seamless Custom AI Chatbot Integration" />
        <meta name="twitter:description" content="Integrate AI chatbots with no hassle, create a custom bot in minutes with SaasbotAI." />
      </Helmet>
        <CssBaseline enableColorScheme />
        <AppAppBar />
        <Hero />
        <div>
          {/* <LogoCollection /> */}
          <Features />
          <Divider />
          <Testimonials />
          <Divider />
          <Highlights />
          <Divider />
          <Pricing />
          <Divider />
          <FAQ />
          <Divider />
          <ContactUs />
          <Footer />
        </div>
        </>

  );
}
