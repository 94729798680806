import React, { useState } from 'react';
import axios from 'axios';
import RemoveIcon from '@mui/icons-material/Remove';
import './chatbot.css'; // Import the CSS file for styling
import Markdown from 'react-markdown'

export const SaasBot = ({
  api_key,
  topBarColor = '#4A90E2',
  backgroundColor = '#f5f5f5',
  bubbleColor = '#4A90E2',
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [sessionId, setSessionId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const toggleChat = () => {
    setIsOpen(!isOpen);
  };

  const resetChat = () => {
    setMessages([]);
    setSessionId(null);
  };

  const sendMessage = async () => {
    if (input.trim() === '') return;

    const userMessage = { sender: 'user', text: input };
    setMessages([...messages, userMessage]);

    setInput(''); // Clears the input field
    setIsLoading(true); // Disables the send button

    try {
      const response = await axios.post(
        '/api/chat/',
        { message: input, session: sessionId },
        {
          headers: {
            Authorization: 'BotKey ' + api_key,
          },
        }
      );

      const botMessage = { sender: 'bot', text: response.data.reply.answer };

      console.log(response.data);

      setSessionId(response.data.session);

      setMessages((prevMessages) => [...prevMessages, botMessage]);
    } catch (error) {
      console.error('API error:', error);
    } finally {
      setIsLoading(false); // Re-enables the send button
    }
  };

  return (
    <div className="chatbot-container" style={{ backgroundColor }}>
      {isOpen && (
        <div className="chatbot-window" style={{ borderColor: topBarColor }}>
          <div
            className="chatbot-header"
            style={{ backgroundColor: topBarColor }}
          >
            <span>ChatBot</span>
            <div className="chatbot-header-buttons">
              <button className="chatbot-button" onClick={resetChat}>
                Reset
              </button>
              <button className="chatbot-button" onClick={toggleChat}>
                <RemoveIcon /> {/* Minimize icon */}
              </button>
            </div>
          </div>
          <div className="chatbot-messages">
            {messages.map((msg, index) => (
              <div
                key={index}
                className={`message ${msg.sender}`}
                style={{
                  backgroundColor:
                    msg.sender === 'user' ? bubbleColor : '#e0e0e0',
                }}
              >
                <Markdown style={{'margin':'0'}}>{msg.text}</Markdown>
              </div>
            ))}
          </div>
          <div className="chatbot-input">
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && !isLoading && sendMessage()}
              placeholder="Type your message..."
              disabled={isLoading} // Disables input during API call
            />
            <button onClick={sendMessage} disabled={isLoading || input.trim() === ''}>
              Send
            </button>
          </div>
        </div>
      )}
      {!isOpen && (
        <div className="chatbot-bubble" onClick={toggleChat}>
          <span>Try</span>
        </div>
      )}
    </div>
  );
};

export default SaasBot;
